<template>
  <div>
    <header>
      <p class="">An invitation to</p>
      <h1 class="text-6xl sm:text-7xl md:text-8xl lg:text-9xl">The Wedding</h1>
      <p>of</p>
      <h2 class="text-5xl sm:text-5xl md:text-6xl lg:text-7xl">Tracy &amp; Ben</h2>
    </header>
    <main>
      <div class="divider"></div>
      <table class="mx-auto">
        <tr>
          <th class="text-right pr-4">Date:</th>
          <td class="text-left">Monday 31<sup>st</sup> October 2022</td>
        </tr>
        <tr>
          <th class="text-right pr-4">Time:</th>
          <td class="text-left">Twelve o'clock</td>
        </tr>
        <tr>
          <th class="text-right pr-4">Venue:</th>
          <td class="text-left"><address>The School House, Abingdon</address></td>
        </tr>
        <tr>
          <th class="text-right pr-4">Dress code:</th>
          <td class="text-left">Your finest regalia</td>
        </tr>
      </table>
      <p>
        Transport has been arranged from our house in Chalgrove to Abingdon and therefore we would like to invite you to
        congregate at our house at a time to be advised for a drink prior to the ceremony. Return transport has been
        arranged for everyone back to Chalgrove for the evening celebration.
      </p>
      <p>Reception to follow at The Nags Head, Abingdon</p>

      <div class="divider"></div>
      <p>Then followed by the evening celebrations</p>
      <table class="mx-auto">
        <tr>
          <th class="text-right pr-4">Time:</th>
          <td class="text-left">Six o'clock till Eleven(ish) o'clock</td>
        </tr>
        <tr>
          <th class="text-right pr-4">Venue:</th>
          <td class="text-left">
            <address>
              <a href="https://lambchalgrove.co.uk/" target="_blank">The Lamb</a>
              <br />2 Mill Lane, Chalgrove, OX44 7SL
            </address>
          </td>
        </tr>
        <tr>
          <th class="text-right pr-4">Dress code:</th>
          <td class="text-left">Smart or casual or halloween costume</td>
        </tr>
      </table>
      <p>
        This will be a Halloween themed evening so please feel free to dress up however this is optional so please do
        not feel you have to. (A change of clothes can be left at our house during the day).
      </p>
      <p>A small buffet will be provided at the evening do.</p>

      <div class="divider"></div>
      <div class="blood">
        <p>
          Gifts - We do not need or expect anything however if you wish to contribute to our “familymoon” we would be
          very grateful.
        </p>
      </div>

      <div class="divider"></div>
      <p>Please RSVP by 31st July (sorry we've not given you much time)</p>
    </main>
  </div>
</template>
